.follower-popup-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  z-index: 999;
  animation: popupShow 0.5s ease-in-out;

  .follower-popup-container {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 368px;
    background-color: #1e1e1e;
    position: absolute;
    border-radius: var(--prim-radius);
    padding: var(--primary-gap);
    overflow-y: auto;
    max-height: 445px;

    &::-webkit-scrollbar {
      display: none;
    }

    .close-btn {
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;
    }

    .popup-title {
      text-align: center;
      margin-bottom: var(--primary-gap);
    }

    .follower-list-container {
      display: flex;
      flex-direction: column;
      gap: var(--secondary-gap);

      .followr-card {
        display: flex;
        align-items: center;
        background-color: var(--primary-background);
        border-radius: 16px;
        gap: var(--secondary-gap);
        padding: 12px 20px;
        cursor: pointer;

        .follower-img {
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        &:hover {
          background-color: var(--secondary-background);
        }
      }
    }
  }
}
