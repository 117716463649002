.notif-card-box {
  display: flex;
  align-items: center;
  background-color: var(--secondary-background);
  padding: 12px 20px;
  width: 100%;
  border-radius: var(--prim-radius);
  gap: var(--secondary-gap);
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }

  .notif-user-img {
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .notif-content {
    display: flex;
    flex-direction: column;
    //align-items: center;
    color: var(--secondary-gray);
    //gap: 4px;
    flex: 1;

    .user-name {
      color: var(--primary-white);
    }
  }

  .notif-date {
    margin-left: auto;
    margin-right: 0;
    color: var(--secondary-gray);
  }
}

.header-primary-container .right-action-container .notification img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.notif-is-read {
  background-color: var(--primary-background);
}
