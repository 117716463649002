.about-us-main-container {
  .about-us-main {
    display: flex;
    flex-direction: column;
    gap: var(--primary-gap);

    .intro {
      font-size: 18px;
    }

    .benefit-of-use {
      .title {
        color: #52bee7;
      }

      .description {
        margin-top: 12px;
      }

      .target-clients {
        margin-top: var(--primary-gap);
        .client-des {
          display: flex;
          flex-direction: column;
          gap: var(--secondary-gap);
          margin-top: 12px;
        }
      }
    }

    .why-choose-us {
      color: #52bee7;

      .point-out {
        font-weight: 600;
      }

      .stress {
        font-size: 28px;
        text-decoration: underline;
      }
    }

    .contact-info {
      display: flex;
      flex-direction: column;
      gap: var(--secondary-gap);
      margin-bottom: 32px;
    }
  }
}
