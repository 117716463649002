.popover-menu-box {
  .popover-btn {
    display: flex;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    background-color: var(--secondary-background);
    backdrop-filter: blur(6px);
    cursor: pointer;

    img {
      margin: auto;
      width: 16px;
      height: auto;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
    }
  }

  .bs-popover-bottom > .popover-arrow::before {
    display: none;
  }

  .bs-popover-bottom > .popover-arrow::after {
    display: none;
  }

  .popover {
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(6px);
    border-radius: var(--prim-radius);
    border: none;
    box-shadow: var(--primary-shadow);
    width: 154px;

    .popover-body {
      padding: 0;
    }

    .popover-menu-main {
      color: var(--primary-white);
      padding: 12px;

      .popover-option {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 8px;
        position: relative;
        border-radius: 30px;
        cursor: pointer;

        img {
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
          width: 14px;
          height: auto;
          margin-top: 0.5px;
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.4);
        }
      }
    }
  }
}
