.report-popup-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  z-index: 9999;
  animation: popupShow 0.5s ease-in-out;

  .report-popup-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 560px;
    background-color: #1e1e1e;
    border-radius: var(--prim-radius);

    .close-btn {
      padding: 25px 25px 0 0;

      img {
        margin-left: auto;
        margin-right: 0;
        cursor: pointer;
      }
    }

    .report-popup-main {
      padding: 20px;
      text-align: center;

      .report-option-buttons {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;

        .report-option {
          padding: var(--secondary-gap);
          border: 1px solid var(--primary-white);
          border-radius: 30px;
          //background-color: var(--primary-background);

          &:hover {
            background-color: var(--secondary-background);
            cursor: pointer;
          }
        }

        .selected {
          background-color: var(--secondary-background);
        }
      }

      .confirmation {
        display: flex;
        gap: var(--primary-gap);
        margin-top: 20px;
      }

      .context {
        padding: 16px 0 20px 0;
      }
    }
  }

  .compelete {
    width: 320px;
  }
}
