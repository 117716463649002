.package-deals-container {
  display: flex;
  flex-direction: column;
  gap: var(--primary-gap);
  margin: 20px 0 48px 0;

  .tab-subtitle {
    .subtitle {
      white-space: pre-wrap;
    }
  }

  .deal-combo-list {
    display: flex;
    gap: var(--primary-gap);
  }

  .pay-instruction {
    .inst-list {
      margin-top: var(--primary-gap);

      ul {
        display: flex;
        flex-direction: column;
        gap: var(--secondary-gap);
      }
    }
  }
}
