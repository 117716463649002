.filter-dropdown-box {
  width: 100%;
  position: relative;

  // bootstrap style - dropdown
  .drowpdown-btn {
    display: flex;
    align-items: center;
    background-color: var(--primary-background);
    border: none;
    width: 100%;
    height: 48px;
    border-radius: 30px;
    padding: 0 20px;
    position: relative;

    --bs-btn-active-bg: var(--primary-background);

    .trigger-icon {
      right: 20px;
      position: absolute;
      z-index: -1;
    }

    .rotate {
      transform: rotate(-180deg);
    }

    &:hover {
      background-color: var(--secondary-background);
    }
  }

  .popover {
    width: 100%;
    --bs-popover-bg: transparent;
    --bs-popover-max-width: 100%;
    border: none;
    box-shadow: none !important;

    .popover-body {
      padding: 16px 20px;
      background-color: #333333;
      border-radius: var(--prim-radius);
    }
  }

  .bs-popover-bottom > .popover-arrow::after {
    display: none;
  }

  // checkbox style

  form {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .checkbox-content {
    display: flex;
    align-items: center;
    gap: 6px;

    input {
      opacity: 0;
      z-index: 99;
      width: 14px;
      height: 14px;
      cursor: pointer;
    }

    .checkmark {
      display: inline-block;
      width: 14px;
      height: 14px;
      background-color: #333333;
      border: 1px solid var(--primary-white);
      border-radius: 4px;
      position: absolute;
    }

    .checked {
      background-color: white;
      background-image: url(../../assets/images/checkbox_check_icon.svg);
      background-repeat: no-repeat;
      background-position: center center;
    }

    .checkbox-label {
      color: #fff;
    }
  }
}
