.notif-box {
  display: flex;
  //position: relative;

  .notifi-light {
    position: relative;
    span {
      top: -0.5px;
      transform: translateX(105%);
      position: absolute;
      width: 11.5px;
      height: 11.5px;
      background-color: #ff0000;
      border-radius: 50%;
      border: 2.5px solid #000;
    }
  }

  .empty-notification-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: var(--primary-white);
    background-color: var(--primary-background);
    border-radius: var(--prim-radius);
    height: 66px;
  }

  .notif-btn {
    padding: 0;
    background: none;
    border: none;

    .nitifi-icon {
      img {
        width: 18px;
        height: 20px;
      }
    }

    &:hover {
      background-color: var(--primary-background);
    }

    --bs-btn-active-bg: var(--primary-background);
  }

  .bs-popover-bottom > .popover-arrow::after {
    display: none;
  }

  .popover {
    width: 368px;
    max-height: 470px;
    background-color: #1e1e1e;
    border-radius: var(--prim-radius);
    border: none;
    box-shadow: var(--primary-shadow);

    --bs-popover-max-width: 100%;

    .popover-body {
      display: flex;
      flex-direction: column;
      gap: var(--secondary-gap);
      padding: var(--primary-gap);
      max-height: 450px;
      overflow-y: hidden;
      cursor: auto;

      &:hover {
        overflow-y: auto;
        margin-right: 4px;
        padding-right: 9px;

        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-track {
          margin: 20px 0;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.5);
          border-radius: 10px;
        }
      }
    }
  }
}
