.auth-popup-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  z-index: 9999;
  animation: popupShow 0.5s ease-in-out;

  .auth-main-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 640px;
    height: 400px;
    border-radius: var(--prim-radius);
    background-color: #000;
    //box-shadow: var(--primary-shadow);

    .close-btn {
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;
    }

    .logo-bg {
      img {
        border-radius: var(--prim-radius) 0 0 var(--prim-radius);
        width: 320px;
        height: auto;
      }
    }

    .auth-main-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      gap: var(--primary-gap);
      align-self: center;

      .auth-btn {
        //display: flex;
        //align-items: center;
        position: relative;

        img {
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .service-privacy-policy {
        text-align: center;
        color: var(--secondary-gray);

        .policy-link {
          display: flex;
          color: var(--primary-white);

          .link {
            cursor: pointer;
          }

          div:first-child {
            color: var(--secondary-gray);
            cursor: initial;
          }
        }
      }
    }
  }
}
