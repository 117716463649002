.chat-main-container {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--primary-background);
  border-radius: var(--prim-radius);
  gap: var(--secondary-gap);
  padding: 20px;
  cursor: pointer;

  &:hover {
    background-color: var(--secondary-background);
  }

  .speaker-pic {
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .chat-main {
    .chat-content {
      padding-top: 4px;
    }
  }

  .latest-date {
    position: absolute;
    top: 20px;
    right: 20px;
    color: var(--secondary-gray);
  }
}

.unread {
  background-color: var(--secondary-background);
}

.selected {
  border: 1px solid var(--primary-white);
  background-color: var(--secondary-background);
}
