.myinfo-main-container {
  width: 100vw;

  .mine-post-btn-group {
    display: flex;
    justify-content: center;
    gap: var(--primary-gap);
    padding: 20px 0;

    button {
      width: 220px;
    }
  }

  .my-posts-container {
    columns: 4;
    column-gap: 24px;
  }
}
