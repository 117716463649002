.assure-popup-model {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  z-index: 9999;
  animation: popupShow 0.5s ease-in-out;

  .assure-popup-main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
    background-color: #1e1e1e;
    border-radius: var(--prim-radius);
    padding: 20px;
    text-align: center;

    .close-btn {
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;
    }

    .popup-content {
      .context {
        padding: 16px 0 20px 0;
        white-space: pre-wrap;
      }

      .emphasize-top-space {
        margin-top: 6px;
      }

      .emphasize-down-space {
        margin-bottom: 6px;
      }
    }

    .btn-group {
      display: flex;
      gap: var(--primary-gap);

      .block-confirm-btn {
        background-color: #fff;
      }
    }
  }
}
