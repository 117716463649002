.msg-bubble-container {
  .msg-bubble-main {
    display: flex;
    gap: var(--secondary-gap);
    margin-top: 12px;

    .comment-user-pic {
      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        object-fit: cover;
        cursor: pointer;
      }
    }

    .user-comment-content {
      padding: 12px;
      background-color: var(--secondary-background);
      border-radius: var(--prim-radius);
      max-width: 100%;
      word-break: break-all;

      .comment-top {
        display: flex;
        align-items: center;
        margin-bottom: 2px;

        .commnet-user-name {
          margin-right: var(--secondary-gap);
          cursor: pointer;
        }

        .comment-date {
          margin-left: auto;
          margin-right: 0;
        }
      }
    }

    .edit-comment-action {
      // display: flex;
      // justify-content: center;
      // align-self: center;
      // width: 20px;
      // height: 20px;
      // //border-radius: 50%;
      // //background-color: var(--primary-background);
      // cursor: pointer;

      // img {
      //   width: 14px;
      //   height: auto;
      // }
    }

    .edit-comment-action {
      align-self: center;

      .edit-comment-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-color: var(--primary-background);
        cursor: pointer;
        border: none;

        img {
          width: 14px;
          height: auto;
        }

        &:hover {
          background-color: var(--secondary-background);
        }

        --bs-btn-active-bg: var(--primary-background);
      }

      .popover-arrow {
        visibility: hidden;
      }

      .popover {
        width: 154px;
        background-color: #313131;
        border-radius: var(--prim-radius);
        border: none;

        --bs-popover-max-width: 100%;

        .popover-body {
          padding: 12px;
          .comment-edit-option {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            padding: 8px;
            position: relative;
            border-radius: 30px;
            color: var(--primary-white);
            cursor: pointer;

            img {
              left: 16px;
              top: 50%;
              transform: translateY(-50%);
              position: absolute;
              width: 14px;
              height: auto;
              margin-top: 1px;
            }

            &:hover {
              background-color: var(--secondary-background);
            }
          }
        }
      }
    }
  }

  .like-reply-action {
    display: flex;
    gap: 16px;
    margin-left: 44px;
    margin-top: var(--secondary-gap);

    .action {
      display: flex;
      align-items: center;
      gap: var(--secondary-gap);
      color: var(--secondary-gray);
      cursor: pointer;

      img {
        height: 13px;
        width: auto;
        filter: brightness(0) saturate(100%) invert(50%) sepia(30%) saturate(6%)
          hue-rotate(122deg) brightness(95%) contrast(89%);
      }

      &:hover {
        color: var(--primary-white);

        img {
          filter: none;
        }
      }
    }

    .liked {
      img {
        filter: brightness(0) saturate(100%) invert(59%) sepia(49%)
          saturate(559%) hue-rotate(313deg) brightness(101%) contrast(100%);
      }

      &:hover {
        img {
          filter: brightness(0) saturate(100%) invert(59%) sepia(49%)
            saturate(559%) hue-rotate(313deg) brightness(101%) contrast(100%);
        }
      }
    }
  }
}
