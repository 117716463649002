.back-to-top-arrow {
  .rush-to-top-btn {
    position: fixed;
    bottom: 20px;
    right: 40px;
    cursor: pointer;
    z-index: 99;

    img {
      transform: rotate(180deg);
      width: 42px;
      height: auto;
    }
  }
}
