.house-detailcard-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .owner-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--primary-gap);

    .owner-profile {
      display: flex;
      align-items: center;
      gap: 12px;
      cursor: pointer;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .follow-btn {
      display: flex;
      //justify-content: center;
      align-items: center;
      height: 34px;
      //width: 76px;
      color: #1e1e1e;
      background-color: var(--primary-white);
      border-radius: 24px;
      gap: 4px;
      padding: 0 12px;

      img {
        width: 16px;
        height: auto;
        filter: brightness(0) saturate(100%) invert(9%) sepia(6%) saturate(11%)
          hue-rotate(35deg) brightness(97%) contrast(94%);
      }
    }

    .is-following-btn {
      background-color: var(--primary-background);
      color: var(--primary-white);

      img {
        filter: brightness(0) saturate(100%) invert(99%) sepia(92%) saturate(1%)
          hue-rotate(196deg) brightness(107%) contrast(100%);
      }
    }
  }

  .property-detail-sec {
    position: relative;
    overflow-y: scroll;
    flex-grow: 1;
    margin-bottom: 68px;

    &::-webkit-scrollbar {
      display: none;
    }

    .dropdown-action {
      display: flex;
      align-items: center;
      position: absolute;
      top: 11px;
      right: 0;
      cursor: pointer;
      width: 14px;
      height: 14px;

      img {
        width: 14px;
        height: auto;
      }
    }

    .property-title {
      width: 80%;
      line-height: 32px;
      //margin-bottom: var(--primary-gap);
    }

    .created-date {
      margin: 10px 0;
      color: var(--secondary-gray);
      text-align: end;
    }

    .property-detail-content {
      line-height: 24px;

      .description {
        white-space: pre-wrap;
      }

      .detail-content-list {
        margin-top: 6px;
      }
    }
  }
}
