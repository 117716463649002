.social-media-share-box {
  .share-popup-btn {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: none;
    background-color: var(--primary-background);

    img {
      width: auto;
      height: 16px;
      filter: brightness(0) saturate(100%) invert(62%) sepia(2%) saturate(9%)
        hue-rotate(359deg) brightness(89%) contrast(87%);
      margin: auto;
    }

    &:hover {
      background-color: var(--primary-background);

      img {
        filter: none;
      }
    }

    --bs-btn-active-bg: var(--primary-background);
  }

  .popover-arrow {
    visibility: hidden;
  }

  .popover {
    //background-color: #333333;
    //border-radius: var(--prim-radius);
    border: none;

    --bs-popover-max-width: 100%;

    .popover-body {
      display: flex;
      gap: var(--secondary-gap);
      padding: 12px;
      cursor: auto;
      position: absolute;
      bottom: 0px;
      right: -24px;
      background-color: #353535;
      border-radius: var(--prim-radius);
    }
  }

  .react-share__ShareButton {
    svg {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    img {
      width: auto;
      height: 40px;
    }
  }
}
