.edit-post-main-container {
  margin-bottom: 220px;

  .error-free-gap {
    padding: 10px;
  }

  .thumbnail-error-gap {
    padding: 0;
  }

  .input-error {
    padding: 8px 20px 10px 20px;
  }

  .edit-btn-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--primary-gap);

    button {
      width: 220px;
    }
  }

  .post-edit-main {
    display: flex;
    gap: var(--primary-gap);

    .edit-left-sec {
      width: 50%;
      .upload-thumbnail-box {
        display: flex;
        background-color: var(--primary-background);
        border-radius: var(--prim-radius);
        height: 172px;
        //margin-bottom: var(--primary-gap);

        .post-thumbnail {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          place-items: center;
          cursor: pointer;
          position: relative;

          .thumbnail-img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }

          .thumbnail-upload-icon {
            img {
              width: 58px;
              height: auto;
              margin-bottom: var(--secondary-gap);
            }
          }
        }

        .has-post-thumbnail {
          &::after {
            display: none;
            content: "上傳封面";
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            border-radius: var(--prim-radius);
            font-size: 16px;
            background-color: rgba(0, 0, 0, 0.2);
            z-index: 1;
            position: absolute;
            top: 0;
            cursor: pointer;
          }

          &:hover {
            &::after {
              display: flex;
            }
          }
        }
      }

      .edit-input {
        display: flex;
        flex-direction: column;
        height: 224px;
      }

      .hashtag-recommendation {
        display: flex;
        flex-wrap: wrap;
        gap: var(--secondary-gap);
        margin-top: 12px;

        .suggest-keyword {
          background-color: var(--secondary-background);
          border-radius: 30px;
          padding: 4px 14px;
          cursor: pointer;
        }
      }

      .sell-or-lease {
        display: flex;
        gap: var(--primary-gap);
      }

      .filter-group {
        margin-top: var(--primary-gap);

        .advanced-btn {
          display: flex;
          align-items: center;
          height: 48px;
          width: 100%;
          background-color: var(--primary-background);
          border-radius: 30px;
          padding: 0 20px;
          position: relative;
          cursor: pointer;

          img {
            position: absolute;
            right: 20px;
          }

          &:hover {
            background-color: var(--secondary-background);
          }
        }

        .transparent {
          background-color: transparent;

          img {
            transform: rotate(-180deg);
          }
        }

        .advanced-filter {
          display: none;
          grid-template-columns: repeat(2, 1fr);
          gap: 16px;
          margin-top: 12px;
        }

        .show-advanced-filter {
          display: grid;
        }
      }
    }

    .edit-right-sec {
      width: 50%;
      background-color: var(--primary-background);
      border-radius: var(--prim-radius);
      height: 620px;

      &:hover {
        .carousel-control-prev,
        .carousel-control-next {
          visibility: visible;
        }
      }

      .carousel-control-prev,
      .carousel-control-next {
        top: 50%;
        bottom: 50%;
        visibility: hidden;

        &::after {
          content: "";
          background-color: rgba(0, 0, 0, 0.8);
          border-radius: 50%;
          width: 30px;
          height: 30px;
          position: absolute;
        }
      }

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        width: 13px;
        height: 13px;
        z-index: 99;
      }

      .carousel-control-prev-icon {
        transform: rotate(90deg);
        background-image: url(../../assets/images/arrow_down_icon.svg);
      }

      .carousel-control-next-icon {
        transform: rotate(-90deg);
        background-image: url(../../assets/images/arrow_down_icon.svg);
      }

      .carousel {
        .carousel-indicators {
          //display: none;
          margin-bottom: 1.5rem;

          button {
            width: 6px;
            height: 6px;
            border-radius: 50%;
          }
        }

        .carousel-inner {
          width: 100%;
          height: 620px;

          .carousel-item,
          .active {
            width: 100%;
            height: 100%;
            position: relative;

            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
              height: 100%;
              object-fit: contain;
            }

            video {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }

  .single-item {
    .carousel-control-prev,
    .carousel-control-next {
      display: none;
    }

    .carousel-indicators {
      display: none;
    }
  }
}
