.title-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  padding: 32px 40px 20px 0;
  margin-left: 300px;
  width: calc(100% - 300px);
  background-color: #000;
  z-index: 999;
}

@media screen and (min-width: 1728px) {
  .title-header-container {
    width: 100%;
    max-width: 1428px; // redused by margin-left (max-width of page is 1728px)
  }
}
