.header-primary-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  position: fixed;
  padding: 32px 40px 20px 0;
  margin-left: 300px;
  width: calc(100% - 300px);
  background-color: #000;
  z-index: 9999;

  .search-bar {
    position: relative;
    .search-btn {
      position: absolute;
      top: 15px;
      bottom: 16px;
      right: 20px;
    }
  }

  .right-action-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .notification {
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 1728px) {
  .header-primary-container {
    width: 100%;
    max-width: 1428px; // redused by margin-left (max-width of page is 1728px)
  }
}
