.package-deal-card-container {
  border-radius: var(--prim-radius);
  padding: var(--primary-gap);
  width: 100%;

  .deal-content {
    text-align: center;

    .deal-price {
      margin-top: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;

      span:last-child {
        text-decoration: line-through;
        color: var(--secondary-gray);
      }
    }

    .deal-benefit {
      padding: 16px 0;
      ul {
        display: flex;
        flex-direction: column;
        gap: 4px;
        text-align: center;
      }
    }
  }
}

.plan-a {
  background-color: var(--primary-background);
}

.plan-b {
  border: 1px solid var(--border-gray);
  background-color: var(--primary-background);
}

.plan-c {
  border: 1px solid #6a7478;
  background: transparent linear-gradient(180deg, #34434a 0%, #34434a00 100%) 0%
    0% no-repeat padding-box;
}

.plan-d {
  border: 1px solid #c49945;
  background: transparent linear-gradient(180deg, #34434a 0%, #34434a00 100%) 0%
    0% no-repeat padding-box;
}

.plan-e {
  border: 1px solid #c49945;
  background: transparent linear-gradient(180deg, #684b2a 0%, #060402 100%) 0%
    0% no-repeat padding-box;

  .order-btn {
    background-color: transparent;
    border: 1px solid #c49945;
    color: #f1c268;
  }
}
