.reply-control-container {
  position: absolute;
  right: 20px;
  bottom: 12px;
  width: 100%;
  background-color: #313131;
  border-radius: 30px;
  width: calc(100% - 40px);
  height: 48px;
  z-index: 99;

  img {
    width: auto;
    height: 16px;
  }

  button {
    background-color: var(--primary-white);
    color: #000;
    height: 32px;
    padding: 0 24px;
    border-radius: var(--prim-radius);
    right: 12px;
    bottom: 8px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.8);
    }
  }

  .upload-file-action {
    right: 104px;
    bottom: 10px;

    img {
      cursor: pointer;
    }
  }

  .action {
    position: absolute;
  }

  .disabled-btn {
    background-color: var(--primary-background);

    &:hover {
      background-color: var(--primary-background);
    }
  }

  // popup of files for upload
  .files-popup-box {
    position: absolute;
    padding: 12px;
    bottom: 120%;
    width: 100%;
    z-index: 99;
    background-color: #313131;
    border-radius: var(--prim-radius);

    .files-popup-main {
      width: 100%;
      height: 200px;
      display: grid;
      grid-template-columns: repeat(auto-fill, 164px);
      gap: 12px;
      overflow-y: hidden;

      &:hover {
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.5);
          border-radius: 10px;
        }
      }

      .upload-file {
        width: 100%;
        aspect-ratio: 1/1;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: var(--prim-radius);
        }

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: var(--prim-radius);
          display: block;
        }

        .app-format {
          width: 100%;
          height: 100%;
          background-color: #5d5d5d;
          border-radius: var(--prim-radius);
          padding: 12px;
          display: flex;
          align-items: flex-end;
        }

        .delete-box {
          width: 100%;
          background: transparent
            linear-gradient(180deg, #00000080 0%, #ffffff00 100%) 0% 0%
            no-repeat padding-box;
          position: absolute;
          padding: 12px 0;
          border-radius: var(--prim-radius) var(--prim-radius) 0 0;
          z-index: 999;

          img {
            width: 20px;
            height: 100%;
            margin-left: auto;
            margin-right: 12px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.reply-control-container-focused {
  height: 110px;
  border-radius: var(--prim-radius);

  .files-popup-box {
    bottom: 110%;
  }

  .comment-close-btn {
    position: absolute;
    bottom: 80px;
    right: 14px;
    z-index: 99;
    cursor: pointer;

    img {
      width: 16px;
      height: auto;
      filter: brightness(0) saturate(100%) invert(62%) sepia(2%) saturate(9%)
        hue-rotate(359deg) brightness(89%) contrast(87%);
    }
  }
}
