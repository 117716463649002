.service-center-main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .service-answer-sec {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    margin-bottom: 32px;
  }
}
