.faq-main-container {
  .faq-main {
    display: flex;
    flex-direction: column;
    gap: var(--primary-gap);
    margin-bottom: 32px;

    .fqa-question {
      .anwswer {
        margin-top: var(--secondary-gap);
      }
    }

    .privacy-link {
      font-size: 20px;
      font-weight: 600;
      cursor: pointer;
      text-decoration: underline;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}

.popup-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  z-index: 9999;
  animation: popupShow 0.5s ease-in-out;
}
