.membership-main-container {
  .tabs-btn-group {
    display: flex;
    gap: var(--primary-gap);

    button {
      width: 220px;
    }
  }
}
