.plan-popup-model {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  z-index: 9999;
  animation: popupShow 0.5s ease-in-out;

  .assure-popup-main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
    background-color: #1e1e1e;
    border-radius: var(--prim-radius);
    padding: 20px;
    text-align: center;

    .popup-top-bg {
      top: 0;
      left: 0;
      position: absolute;
      img {
        border-radius: var(--prim-radius) var(--prim-radius) 0 0;
        width: 100%;
        height: auto;
      }
    }

    .popup-content {
      margin-top: 150px;
      .context {
        display: inline-block;
        width: 150px;
        padding: 16px 0 20px 0;
        text-align: center;
      }
    }

    .btn-group {
      display: flex;
      gap: var(--primary-gap);
    }
  }
}
