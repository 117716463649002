.profile-card-container {
  height: 230px;
  padding: 40px;
  position: relative;
  display: flex;
  align-items: center;

  .user-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 230px;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    border-radius: var(--prim-radius);
  }

  .backdrop {
    top: 0;
    left: 0;
    width: 100%;
    height: 230px;
    position: absolute;
    z-index: -1;
    border-radius: var(--prim-radius);
    background-color: rgba(0, 0, 0, 0.2);
  }

  .card-content {
    display: flex;
    gap: var(--primary-gap);

    .user-pic {
      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  .content-group {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .user-detail {
      .decription {
        line-height: 24px;
        margin-top: 12px;
      }
    }

    .social-counts {
      display: flex;
      align-items: center;

      div {
        display: flex;
      }

      .vertical-bar {
        width: 1px;
        height: 12px;
        background-color: var(--primary-white);
        margin: 0 20px;
      }

      .post-count {
        span {
          &::after {
            content: "貼文";
            font-size: 14px;
            font-weight: 400;
            padding-left: 4px;
          }
        }
      }

      .follower-count {
        cursor: pointer;
        span {
          &::after {
            content: "粉絲";
            font-size: 14px;
            font-weight: 400;
            padding-left: 4px;
          }
        }

        &:hover {
          span {
            &::after {
              text-decoration: underline;
              font-weight: 700;
            }
          }
        }
      }

      .like-count {
        span {
          &::after {
            content: "喜歡";
            font-size: 14px;
            font-weight: 400;
            padding-left: 4px;
          }
        }
      }

      .block-count {
        cursor: pointer;
        span {
          &::after {
            content: "封鎖";
            font-size: 14px;
            font-weight: 400;
            padding-left: 4px;
          }
        }

        &:hover {
          span {
            &::after {
              text-decoration: underline;
              font-weight: 700;
            }
          }
        }
      }
    }
  }

  .profile-btn-group {
    display: flex;
    gap: var(--secondary-gap);
    position: absolute;
    bottom: var(--primary-gap);
    right: 40px;
    button {
      height: 48px;
      width: 160px;
      border-radius: 24px;
      background-color: var(--secondary-background);
      backdrop-filter: blur(6px);

      &:hover {
        background-color: rgba(255, 255, 255, 0.4);
      }
    }

    .to-follow {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}
