.new-notifi-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 1728px;
  height: 100%;
  background-color: transparent;
  z-index: 99999;
}

.new-notif-card-box {
  position: absolute;
  top: 70px;
  transform: translateX(-40px);
  display: flex;
  align-items: center;
  background-color: #353535;
  padding: 12px 20px;
  width: 328px;
  border-radius: var(--prim-radius);
  gap: var(--secondary-gap);
  cursor: pointer;
  right: 0;
  z-index: 99999;

  .new-notif-user-img {
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .new-notif-content {
    display: flex;
    flex-direction: column;
    color: var(--secondary-gray);
    flex: 1;

    .user-name {
      color: var(--primary-white);
    }
  }
}

.header-primary-container .right-action-container .notification img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

@media screen and (min-width: 1728px) {
  .new-notifi-popup {
    left: 50%;
    transform: translateX(-50%);
  }
}
