.primary-textarea {
  background-color: var(--primary-background);
  border: none;
  resize: none;
  border-radius: var(--prim-radius);
  color: var(--primary-white);
  padding: 14px 20px;
  width: 100%;
  height: 100%;
  display: block;

  &::placeholder {
    color: var(--secondary-gray);
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
