.publish-main-container {
  .publish-btn-group {
    display: flex;
    gap: var(--primary-gap);
    margin-bottom: var(--primary-gap);

    button {
      width: 220px;
    }

    .btn-group-publish {
      display: flex;
      width: 100%;
      gap: var(--primary-gap);

      .size-warning {
        align-self: end;
        margin-left: auto;
        margin-right: 0;
      }
    }

    .btn-group-next {
      display: flex;
      width: 100%;

      .size-warning {
        align-self: end;
      }

      .upload-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 220px;
        cursor: pointer;
        margin-left: auto;
        margin-right: var(--primary-gap);
      }
    }
  }

  label {
    width: 100%;
    height: 100%;
  }

  .has-img-label {
    height: 100vh;
  }

  .upload-area {
    display: flex;
    background-color: var(--primary-background);
    border-radius: var(--prim-radius);
    height: 232px;
    cursor: pointer;

    .upload-file-group {
      margin: auto;

      .upload-text {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 58px;
          height: auto;
        }

        span:nth-child(2) {
          margin: 18px 0 10px 0;
        }

        .reminder {
          text-align: center;
          max-width: 680px;
          color: var(--secondary-gray);
        }
      }
    }

    .upload-file {
      width: 100%;
      aspect-ratio: 1/1;
      img {
        width: 100%;
        aspect-ratio: 1/1;
        object-fit: cover;
        border-radius: var(--prim-radius);
      }
    }
  }

  .has-img {
    background-color: #000;
    border-radius: 0;
    height: 100%;
    //cursor: initial;

    .upload-file-group {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      width: 100%;
      height: fit-content;
      margin: 0;
      gap: var(--primary-gap);

      .upload-file {
        position: relative;

        .delete-box {
          width: 100%;
          background: transparent
            linear-gradient(180deg, #00000080 0%, #ffffff00 100%) 0% 0%
            no-repeat padding-box;
          position: absolute;
          padding: 20px 0;
          border-radius: var(--prim-radius) var(--prim-radius) 0 0;
          z-index: 999;

          img {
            width: 24px;
            height: 100%;
            margin-left: auto;
            margin-right: 20px;
            cursor: pointer;
          }
        }

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: var(--prim-radius);
        }
      }
    }
  }
}
