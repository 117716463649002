.service-inquiry-menu-box {
  width: 100%;
  position: relative;

  .inquiry-menu-btn {
    width: 100%;
    border: none;

    &:hover {
      background-color: #606060;
    }

    --bs-btn-active-bg: var(--secondary-background);
  }

  .show-active {
    background-color: #606060;
  }

  .popover {
    width: 100%;
    --bs-popover-bg: transparent;
    --bs-popover-max-width: 100%;
    border: transparent;

    .popover-body {
      padding: 12px 12px;
      background-color: #606060;
      border-radius: var(--prim-radius);
    }
  }

  .bs-popover-bottom > .popover-arrow::after {
    display: none;
  }

  .inquiry-menu-list {
    color: var(--primary-white);

    li {
      text-align: center;
      padding: var(--secondary-gap);
      border-radius: 30px;
      cursor: pointer;

      &:hover {
        background-color: var(--secondary-background);
      }
    }
  }
}
