.system-popup-model {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 999;

  .system-popup-container {
    position: absolute;
    top: 30px;
    left: 50%;
    min-width: 220px;
    height: 48px;
    background-color: var(--primary-white);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    box-shadow: 3px 3px 42px #ffffff66;
    padding: 0 32px;

    .main-msg {
      color: #000;
    }

    .status-icon {
      margin-right: 8px;
    }
  }
}
