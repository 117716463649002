.media-popup-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  backdrop-filter: blur(6px);
  z-index: 999;
  animation: popupShow 0.5s ease-in-out;

  .media-main-container {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;

    .close-btn {
      position: absolute;
      display: flex;
      top: 25px;
      left: 25px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--secondary-background);
      cursor: pointer;

      img {
        width: 16px;
        height: auto;
        margin: auto;
      }
    }

    .media-box {
      display: flex;
      justify-content: center;
      width: 90%;
      height: 100%;

      img {
        // width: auto;
        // height: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }

      video {
        // width: auto;
        // height: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }
}
