.custom-deal-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--primary-gap);
  border-radius: var(--prim-radius);
  padding: var(--primary-gap);
  width: 100%;
  //temporaray
  background-color: var(--primary-background);

  .deal-content {
    text-align: center;
    width: 100%;

    .deal-price {
      margin-top: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;

      span:last-child {
        text-decoration: line-through;
        color: var(--secondary-gray);
      }
    }

    .deal-benefit {
      padding: 16px 0;
      ul {
        display: flex;
        flex-direction: column;
        gap: 4px;
        text-align: center;
      }
    }
  }
}
