.comment-input-box {
  height: 48px;
  .tag-user {
    position: absolute;
    top: 10px;
    left: 20px;
    right: 20px;
    z-index: 999;
    color: var(--secondary-gray);
  }

  .comment-input {
    width: 100%;
    height: 48px;
    padding: 12px 50px 14px 20px;
    color: var(--primary-white);
    resize: none;
    border: none;
    background-color: #353535;
    border-radius: 30px;

    &::placeholder {
      color: var(--secondary-gray);
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .comment-close-btn {
    position: absolute;
    bottom: 70px;
    right: 20px;
    z-index: 999;
    cursor: pointer;

    img {
      width: 16px;
      height: auto;
      filter: brightness(0) saturate(100%) invert(62%) sepia(2%) saturate(9%)
        hue-rotate(359deg) brightness(89%) contrast(87%);
    }
  }

  .comment-send-btn {
    position: absolute;
    bottom: 14px;
    right: 20px;
    z-index: 999;
    cursor: pointer;

    img {
      width: 16px;
      height: auto;
    }
  }

  .disable-comment {
    img {
      filter: brightness(0) saturate(100%) invert(62%) sepia(2%) saturate(9%)
        hue-rotate(359deg) brightness(89%) contrast(87%);
    }
  }

  .comment-input-focused {
    position: absolute;
    bottom: 0;
    height: 100px;
    z-index: 99;
    border-radius: var(--prim-radius);
  }
}

.state-focused {
  position: absolute;
  width: 100%;
  height: 120px;
  background-color: #1e1e1e;
  bottom: 0;
  z-index: 99;
  //border-top: 1px solid #353535;
}

.state-focused-height {
  position: absolute;
  width: 100%;
  height: 140px;
  background-color: #1e1e1e;
  bottom: 0;
  z-index: 99;
}
