.primary-input {
  width: 100%;
  height: 48px;
  border-radius: 30px;
  background-color: var(--primary-background);
  padding: 14px 20px;
  color: var(--primary-white);

  &::placeholder {
    color: var(--secondary-gray);
  }
}
