@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans+TC:wght@100..900&display=swap");
@import "~bootstrap/scss/bootstrap";

body {
  color: white;
  box-sizing: border-box;
  background-color: #000;
}

.App {
  display: flex;
}

img {
  display: block;
}

button {
  color: var(--primary-white);
}

:root {
  /* Fonts */
  --main-font-family: "Noto Sans TC", "Montserrat", sans-serif;

  /* Color Branding */
  --primary-white: #fff;
  --secondary-gray: rgba(255, 255, 255, 0.5);

  /* Background and Border */
  --primary-background: rgba(255, 255, 255, 0.1);
  --secondary-background: rgba(255, 255, 255, 0.2);
  --border-gray: rgba(255, 255, 255, 0.2);
  --border-gold: #786540;

  /* Gap Spacing */
  --primary-gap: 20px;
  --secondary-gap: 8px;
  /* Border Radius */
  --prim-radius: 16px;

  /* box shadow */
  --primary-shadow: 0 1px 20px rgba(255, 255, 255, 0.15),
    0 1px 20px rgba(255, 255, 255, 0.15);
}

/* main body spacing */
.main-body-spacing {
  flex: 1;
  padding: 108px 40px 0 300px;
}

/* Font Size */

.b-16 {
  font-size: 16px;
  font-weight: 700;
}

.b-14 {
  font-size: 14px;
  font-weight: 700;
}

.b-12 {
  font-size: 12px;
  font-weight: 700;
}

.sb-24 {
  font-size: 24px;
  font-weight: 600;
}

.sb-16 {
  font-size: 16px;
  font-weight: 600;
}

.sb-14 {
  font-size: 14px;
  font-weight: 600;
}

.sb-12 {
  font-size: 12px;
  font-weight: 600;
}

.m-16 {
  font-size: 16px;
  font-weight: 500;
}

.m-24 {
  font-size: 24px;
  font-weight: 500;
}

.r-24 {
  font-size: 24px;
  font-weight: 400;
}

.r-16 {
  font-size: 16px;
  font-weight: 400;
}

.r-14 {
  font-size: 14px;
  font-weight: 400;
}

.r-12 {
  font-size: 12px;
  font-weight: 400;
}

/* Buttons */
.primary-btn {
  height: 48px;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  border-radius: 30px;
  background-color: var(--primary-background);

  &:hover {
    background-color: var(--secondary-background);
  }
}

.secondary-btn {
  height: 48px;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  border-radius: 30px;
  background-color: var(--secondary-background);
}

.white-btn {
  height: 48px;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  border-radius: 30px;
  background-color: var(--primary-white);
  color: #000;

  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.no-scroll {
  overflow: hidden;
}

/* Animation */
@keyframes popupShow {
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
}

@media screen and (min-width: 1728px) {
  .responsive-large-screen {
    position: relative;
  }

  .responsive-large-screen {
    width: 1728px;
    margin: 0 auto;
    position: relative;
  }
}
