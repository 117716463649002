.house-itemcard-container {
  position: relative;
  width: 100%;
  margin-bottom: 24px;
  break-inside: avoid;

  .house-item-present {
    display: flex;
    cursor: pointer;
    position: relative;

    &:hover {
      .house-card-pic {
        img {
          opacity: 0.8;
        }
      }
    }

    .house-card-pic {
      width: 100%;
      img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: var(--prim-radius);
        //aspect-ratio: 1/1.2;
        //object-fit: cover;
      }
    }

    .playhead-if-video {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      img {
        width: 64px;
        height: auto;
      }
    }

    .top-card-info {
      width: 100%;
      position: absolute;
      //top: 16px;
      //left: 16px;
      padding: 16px 16px;
      display: flex;
      align-items: center;
      z-index: 1;
      .owner-pic {
        display: flex;
        align-items: center;
        gap: var(--secondary-gap);
        img {
          width: 24px;
          height: 24px;
          background-color: aliceblue;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .likes-action {
        margin-left: auto;
        margin-right: 0;
        display: flex;
        align-items: center;
        gap: 6px;
        img {
          width: 18px;
          height: auto;
          cursor: pointer;

          &:hover {
            filter: brightness(0) saturate(100%) invert(59%) sepia(49%)
              saturate(559%) hue-rotate(313deg) brightness(101%) contrast(100%);
          }
        }
      }

      .liked-by-user {
        img {
          filter: brightness(0) saturate(100%) invert(59%) sepia(49%)
            saturate(559%) hue-rotate(313deg) brightness(101%) contrast(100%);
        }
      }

      //backdrop
      &::after {
        left: 0;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background: transparent
          linear-gradient(180deg, #00000080 0%, #ffffff00 100%) 0% 0% no-repeat
          padding-box;
        border-radius: var(--prim-radius) var(--prim-radius) 0 0;
        z-index: -1;
      }
    }
  }

  .house-item-description {
    margin-top: var(--secondary-gap);
    cursor: pointer;
  }
}
