.chatlist-main-container {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .chat-main-sec {
    position: relative;
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--primary-gap);

    .shared-link-reminder {
      position: absolute;
      top: -50px;
      left: 50%;
      padding-left: 12px;
      z-index: 999;
    }

    .disconnection-reminder {
      position: absolute;
      top: -27px;
      left: 50%;
      padding-left: 12px;
      z-index: 999;
    }

    .chatlist-group {
      display: flex;
      flex-direction: column;
      gap: var(--secondary-gap);
      height: calc(100vh - 140px);
      overflow-y: auto;
      border-radius: var(--prim-radius);

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .chatbox-sec {
      position: relative;
      background-color: var(--primary-background);
      border-radius: var(--prim-radius);
      margin-bottom: 32px;

      .unselected-box {
        height: 100%;

        .unselected-state {
          height: calc(100% - 40px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          img {
            width: 56px;
            height: auto;
            margin-bottom: 12px;
          }
        }
      }
    }
  }
}
