.chatbox-main-container {
  position: relative;

  .talker-title {
    display: flex;
    align-items: center;
    border-radius: var(--prim-radius);
    background-color: var(--primary-background);
    gap: var(--secondary-gap);
    padding: 12px 20px;

    .talker-pic {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
      cursor: pointer;
    }

    .talker-name {
      cursor: pointer;
    }
  }

  .chatbox-main-area {
    padding: 0 var(--primary-gap);
    height: calc(100vh - 276px); /* 內容滾動 */
    overflow-y: scroll; /* 內容滾動 */

    .new-msg-tag {
      position: absolute;
      top: 72px;
      left: 50%;
      transform: translateX(-50%);
      background-color: rgba(0, 0, 0, 0.7);
      padding: 6px 16px;
      border-radius: 20px;
      cursor: pointer;
      border: 1px solid var(--primary-white);
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .record-date {
      text-align: center;
    }

    .record-date {
      margin-top: var(--primary-gap);
    }
  }

  .rush-to-bottom-btn {
    position: absolute;
    bottom: 12px;
    right: 20px;
    cursor: pointer;

    img {
      width: 42px;
      height: auto;
    }
  }
}
