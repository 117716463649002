.download-main-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  backdrop-filter: blur(6px);
  z-index: 9999;
  animation: popupShow 0.5s ease-in-out;

  .download-main {
    img {
      width: auto;
      height: 100%;
      object-fit: cover;
    }

    .download-sec {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        width: 100px;
        height: auto;
      }

      .brand-description {
        text-align: center;
        margin-top: 12px;
        line-height: 24px;
        white-space: pre-wrap;
      }
      .store-btn-group {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-top: var(--primary-gap);

        img {
          height: 36px;
          width: auto;
        }
      }
    }
  }
}
