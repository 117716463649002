.maintenance-popup-modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  z-index: 9999;
  animation: popupShow 0.5s ease-in-out;

  .maintenance-popup-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 640px;
    height: 400px;
    border-radius: var(--prim-radius);
    background-color: #000;
    //box-shadow: var(--primary-shadow);

    .logo-bg {
      img {
        border-radius: var(--prim-radius) 0 0 var(--prim-radius);
        width: 320px;
        height: auto;
      }
    }

    .maintenance-main-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      gap: 16px;
      align-self: center;

      .maintenance-content {
        text-align: center;

        .message {
          white-space: pre-wrap;
        }
      }
    }
  }
}
