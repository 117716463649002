.purchased-deals-container {
  //margin-top: 32px;

  .sec-title {
    display: flex;
    align-items: center;
    margin-top: 32px;

    .current-expiry-date {
      margin-top: 3px;
    }
  }

  .balance-group {
    display: flex;
    margin-top: var(--primary-gap);
    gap: var(--primary-gap);

    .counts-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border: 1px solid var(--border-gold);
      padding: 20px;
      border-radius: 30px;
      background-color: var(--primary-background);
    }
  }
}
