.search-filter-box {
  display: flex;

  // control visibility of filter - start
  .filter-popover-body {
    display: none;
  }

  .popover-body-show {
    display: block;
    left: 50% !important;
    transform: translate3d(-50%, 74px, 0px) !important;
  }
  ///////////////////////

  .filter-btn {
    padding: 0;
    background: none;
    border: none;

    img {
      width: auto;
      height: 20px;
    }

    &:hover {
      background-color: var(--primary-background);
    }

    --bs-btn-active-bg: var(--primary-background);
  }

  .bs-popover-bottom > .popover-arrow::after {
    display: none;
  }

  .popover {
    width: 490px;
    background-color: #1e1e1e;
    border-radius: var(--prim-radius);
    border: none;
    box-shadow: var(--primary-shadow);

    --bs-popover-max-width: 100%;

    .popover-body {
      display: flex;
      flex-direction: column;
      gap: var(--primary-gap);
      padding: 16px 20px;
      color: var(--primary-white);

      .search-filter-btn {
        display: flex;
        gap: var(--primary-gap);
      }

      .search-filter-dropdown {
        .advanced-btn {
          display: flex;
          align-items: center;
          height: 48px;
          width: 100%;
          background-color: var(--primary-background);
          border-radius: 30px;
          padding: 0 20px;
          position: relative;
          cursor: pointer;
          margin-top: var(--primary-gap);

          img {
            position: absolute;
            right: 20px;
          }

          &:hover {
            background-color: var(--secondary-background);
          }
        }

        .transparent {
          background-color: transparent;

          img {
            transform: rotate(-180deg);
          }
        }

        .advanced-filter {
          display: none;
          grid-template-columns: repeat(2, 1fr);
          gap: 16px;
          margin-top: 12px;
        }

        .advanced-filter-show {
          display: grid;
        }
      }

      .search-or-clean {
        display: flex;
        gap: var(--primary-gap);

        .clean-btn {
          width: 100%;
          height: 48px;
          border: 1.5px solid var(--secondary-background);
          border-radius: 30px;

          &:hover {
            background-color: var(--secondary-background);
          }
        }

        .all-btn {
          width: 100%;
          height: 48px;
          border: 1.5px solid var(--primary-white);
          border-radius: 30px;

          &:hover {
            background-color: var(--secondary-background);
          }
        }
      }
    }
  }
}
