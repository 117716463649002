.post-popup-model {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  z-index: 9999;
  animation: popupShow 0.5s ease-in-out;

  .close-btn {
    position: absolute;
    display: flex;
    top: 5%;
    left: 25px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--secondary-background);
    cursor: pointer;

    img {
      width: 16px;
      height: auto;
      margin: auto;
    }
  }

  .post-main-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100vw - 30%);
    height: calc(100vh - 10%);
    background-color: #1e1e1e;
    border-radius: var(--prim-radius);

    .post-main-content {
      // display: grid;
      // grid-template-columns: repeat(2, 1fr);
      display: flex;
      gap: var(--primary-gap);
      height: 100%;

      .post-presentation {
        //background-color: var(--primary-background);
        width: 50%;
        border-radius: var(--prim-radius) 0 0 var(--prim-radius);
        height: 100%;

        &:hover {
          .carousel-control-prev,
          .carousel-control-next {
            visibility: visible;
          }
        }

        .carousel-control-prev,
        .carousel-control-next {
          top: 50%;
          //transform: translateY(-50%);
          bottom: 50%;
          visibility: hidden;

          &::after {
            content: "";
            background-color: rgba(0, 0, 0, 0.8);
            border-radius: 50%;
            width: 30px;
            height: 30px;
            position: absolute;
          }
        }

        .carousel-control-prev-icon,
        .carousel-control-next-icon {
          width: 13px;
          height: 13px;
          z-index: 99;
        }

        .carousel-control-prev-icon {
          transform: rotate(90deg);
          background-image: url(../../assets/images/arrow_down_icon.svg);
        }

        .carousel-control-next-icon {
          transform: rotate(-90deg);
          background-image: url(../../assets/images/arrow_down_icon.svg);
        }

        .carousel {
          height: 100%;
          .carousel-indicators {
            //display: none;

            button {
              width: 6px;
              height: 6px;
              border-radius: 50%;
            }
          }

          .carousel-inner {
            width: 100%;
            height: 100%;

            .carousel-item,
            .active {
              width: 100%;
              height: 100%;
              position: relative;

              img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                object-fit: contain;
              }

              video {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                object-fit: contain;
              }

              // .img-fit-width {
              //   width: 100%;
              //   height: auto;
              //   object-fit: cover; // Ensures the image covers the area without distorting aspect ratio
              //   position: absolute;
              //   top: 50%;
              //   left: 50%;
              //   transform: translate(-50%, -50%);
              // }

              // .img-fit-height {
              //   height: 100%; // Adjust this based on your design requirements
              //   width: auto;
              //   object-fit: cover;
              //   position: absolute;
              //   top: 50%;
              //   left: 50%;
              //   transform: translate(-50%, -50%);
              // }
            }
          }
        }
      }

      .post-interaction-content {
        display: flex;
        flex-direction: column;
        padding: 20px 20px 20px 0;
        width: 50%;
        position: relative;

        .social-main-sec {
          width: 100%;
          position: absolute;
          padding: 20px 20px 20px 0;
          background-color: #1e1e1e;
          bottom: 0;
          border-radius: 0 0 var(--prim-radius) 0;
          left: -1px;

          .social-action-group {
            display: flex;
            gap: var(--secondary-gap);
            position: relative;

            div:first-child {
              flex: 1;
            }

            .record-btn {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: var(--secondary-gap);
              background-color: var(--primary-background);
              width: 92px;
              height: 48px;
              border-radius: 30px;
              color: var(--secondary-gray);

              img {
                width: auto;
                height: 16px;
                filter: brightness(0) saturate(100%) invert(62%) sepia(2%)
                  saturate(9%) hue-rotate(359deg) brightness(89%) contrast(87%);
              }

              &:hover {
                color: var(--primary-white);

                img {
                  filter: none;
                }
              }
            }

            .like-record {
              &:hover {
                img {
                  filter: brightness(0) saturate(100%) invert(59%) sepia(49%)
                    saturate(559%) hue-rotate(313deg) brightness(101%)
                    contrast(100%);
                }
              }
            }

            .post-liked {
              img {
                filter: brightness(0) saturate(100%) invert(59%) sepia(49%)
                  saturate(559%) hue-rotate(313deg) brightness(101%)
                  contrast(100%);
              }
            }

            // .msg-record {
            //   &:hover {
            //     img {
            //       filter: none;
            //     }
            //   }
            // }
          }
        }
      }
    }
  }

  .single-item {
    .carousel-control-prev,
    .carousel-control-next {
      display: none;
    }

    .carousel-indicators {
      display: none;
    }
  }
}
