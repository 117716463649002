.reply-input-box {
  padding: 10px 20px;

  .reply-input {
    width: 96%;
    background-color: transparent;
    border: none;
    resize: none;
    color: var(--primary-white);

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
