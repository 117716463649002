.policy-page-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 9999;
  
    .policy-page-main {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 740px;
      height: 400px;
      overflow: auto;
      background-color: #000;
      border-radius: var(--prim-radius);
      padding: 32px;
  
      &::-webkit-scrollbar {
        display: none;
      }
  
      .close-btn {
        position: absolute;
        top: 25px;
        right: 25px;
        cursor: pointer;
      }
  
      .sec-title {
        margin-bottom: var(--primary-gap);
      }
  
      .update-date {
        margin-bottom: 12px;
        color: var(--secondary-gray);
      }
  
      .policy-list {
        display: flex;
        flex-direction: column;
        gap: var(--primary-gap);
  
        .policy-title {
          margin-bottom: var(--secondary-gap);
        }
  
        .article-list {
          display: flex;
          flex-direction: column;
          gap: var(--secondary-gap);
        }
      }
    }
  }
  