.navbar-primary-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 36px 0 0 40px;
  width: 260px;
  height: 100vh;
  position: fixed;
  z-index: 99;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  .nav-logo-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;
    img {
      width: 104px;
      height: auto;
    }
  }

  .nav-tab-container {
    //margin-top: 56px;
    margin-top: 26px;
    width: 100%;
    .tab-links-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .tab-link {
        display: flex;
        align-items: center;
        padding: 0 16px;
        height: 48px;
        display: flex;
        color: var(--primary-white);
        //border-radius: 30px;
        gap: 14px;
        cursor: pointer;

        img {
          width: 16px;
          height: auto;
        }

        &:hover {
          background-color: var(--secondary-background);
          border-radius: 30px;
        }
      }
    }
  }

  .my-info-actions {
    width: 100%;
    margin-top: 12px;

    .my-info-tab {
      display: flex;
      align-items: center;
      color: var(--primary-white);
      gap: 14px;
      height: 48px;
      padding: 0 16px 0 12px;
      cursor: pointer;
      //margin-bottom: 8px;

      .my-pic {
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 50%;
        object-fit: cover;
      }

      .arrow-icon {
        margin-left: auto;
        margin-right: 6px;
        //transition: 0.3s;
      }

      .rotate {
        transform: rotate(-180deg);
        //transition: 0.3s;
      }

      &:hover {
        background-color: var(--secondary-background);
        border-radius: 30px;
      }
    }

    .my-info-links-group {
      margin-top: 8px;
      display: flex;
      position: relative;

      .vertical-bar {
        width: 2px;
        height: auto;
        background-color: var(--border-gray);
        border-radius: 5px;
        margin: 0 23px;
      }

      .my-info-links-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;

        .my-info-link {
          display: flex;
          align-items: center;
          height: 48px;
          padding: 14px 20px;
          color: var(--primary-white);
          cursor: pointer;

          &:hover {
            background-color: var(--secondary-background);
            border-radius: 30px;
          }
        }
      }
    }
  }

  .about-us-tab-btn {
    width: 100%;
    margin-top: 12px;
    //margin-bottom: 16px;

    .about-us-tab {
      display: flex;
      align-items: center;
      padding: 0 16px;
      height: 48px;
      display: flex;
      color: var(--primary-white);
      //border-radius: 30px;
      gap: 14px;
      cursor: pointer;

      img {
        width: 16px;
        height: auto;
      }

      &:hover {
        background-color: var(--secondary-background);
        border-radius: 30px;
      }
    }
  }

  .fqa-tab-btn {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 16px;

    .faq-tab {
      display: flex;
      align-items: center;
      padding: 0 16px;
      height: 48px;
      display: flex;
      color: var(--primary-white);
      //border-radius: 30px;
      gap: 14px;
      cursor: pointer;

      img {
        width: 16px;
        height: auto;
      }

      &:hover {
        background-color: var(--secondary-background);
        border-radius: 30px;
      }
    }
  }

  .auth-btn-container {
    margin-top: auto;
    margin-bottom: 32px;
    width: 100%;
  }

  .active-tab {
    border: 1px solid var(--primary-white);
    border-radius: 30px;
  }

  .active-subtab {
    border: 1px solid var(--primary-white);
    border-radius: 30px;

    &::before {
      content: "";
      width: 2px;
      height: 48px;
      background-color: var(--primary-white);
      position: absolute;
      border-radius: 5px;
      left: 23px;
    }
  }
}
