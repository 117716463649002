.transaction-popup-model {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(6px);
  z-index: 9999;
  animation: popupShow 0.5s ease-in-out;

  .transaction-popup-main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
    background-color: #1e1e1e;
    border-radius: var(--prim-radius);
    padding: 20px;
    text-align: center;

    .close-btn {
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;
    }

    .radio-group {
      display: flex;
      justify-content: space-between;
      margin: 12px 0;

      .radio-label {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 22px;
        cursor: pointer;
      }

      .radio-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      .radio-input:checked ~ .checkmark {
        //background-color: #fff;
      }

      .radio-input:checked ~ .checkmark:after {
        display: block;
      }

      .checkmark {
        position: absolute;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: transparent;
        border: 1px solid var(--primary-white);
        border-radius: 50%;
      }

      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: var(--primary-white);
      }
    }

    .input-top {
      display: flex;
      flex-direction: column;
      gap: var(--secondary-gap);
      margin-top: var(--primary-gap);

      .error {
        text-align: start;
      }
    }

    .input-bottom {
      //height: 160px;
      .input-group {
        display: flex;
        flex-direction: column;
        gap: var(--secondary-gap);
      }

      .error {
        text-align: start;
      }
    }

    .transaction-submit-btn {
      margin-top: var(--primary-gap);
    }
  }
}
