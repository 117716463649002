.sender-bubble-container {
  display: flex;
  gap: var(--secondary-gap);
  margin-top: 12px;

  .report-message-action {
    align-self: center;

    .report-action-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      background-color: var(--primary-background);
      cursor: pointer;
      border: none;

      img {
        width: 14px;
        height: auto;
      }

      &:hover {
        background-color: var(--secondary-background);
      }

      --bs-btn-active-bg: var(--primary-background);
    }

    .popover-arrow {
      visibility: hidden;
    }

    .popover {
      width: 154px;
      background-color: #313131;
      border-radius: var(--prim-radius);
      border: none;

      --bs-popover-max-width: 100%;

      .popover-body {
        padding: 12px;
        .message-edit-option {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding: 8px;
          position: relative;
          border-radius: 30px;
          color: var(--primary-white);
          cursor: pointer;

          img {
            left: 16px;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            width: 14px;
            height: auto;
            margin-top: 1px;
          }

          &:hover {
            background-color: var(--secondary-background);
          }
        }
      }
    }
  }

  .sender-pic {
    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .sender-chat-content {
    display: flex;
    flex-direction: column;
    padding: 12px;
    background-color: var(--secondary-background);
    border-radius: var(--prim-radius);
    max-width: 360px;
    word-wrap: break-word; // make sure text doest go beyond the box

    .text {
      white-space: pre-wrap;
    }

    .msg-files-box {
      // display: flex;
      // flex-direction: column;
      // gap: var(--secondary-gap);
      // max-width: 164px;

      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: var(--secondary-gap);

      .file-content {
        cursor: pointer;
        flex: 1 164px;

        img {
          display: block;
          width: 100%;
          height: 164px;
          object-fit: cover;
          border-radius: var(--prim-radius);
        }

        .msg-file-video {
          position: relative;

          video {
            display: block;
            width: 100%;
            height: 164px;
            object-fit: cover;
            border-radius: var(--prim-radius);
          }

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 40px;
            height: auto;
          }
        }

        .file-to-download {
          border-radius: var(--prim-radius);
          background-color: #313131;

          a {
            display: block;
            width: 100%;
            height: 164px;
            color: var(--primary-white);
            padding: 12px;
          }
        }
      }
    }

    .files-down-space {
      margin-bottom: 4px;
    }
  }

  // .report-action {
  //   display: flex;
  //   justify-content: center;
  //   align-self: center;
  //   width: 28px;
  //   height: 28px;
  //   border-radius: 50%;
  //   background-color: var(--primary-background);
  //   cursor: pointer;

  //   img {
  //     width: 14px;
  //     height: auto;
  //   }
  // }
}

.sender-msg-timestamp {
  padding: 2px 0 0 32px;
  color: var(--secondary-gray);
}
