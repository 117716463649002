.service-box-container {
  border-radius: var(--prim-radius);
  background-color: var(--primary-background);
  padding: 20px 20px 72px 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .chatbox-main-area {
    overflow-y: scroll;
    height: calc(100vh - 292px);

    .new-msg-tag {
      position: absolute;
      top: 2%;
      left: 50%;
      transform: translateX(-50%);
      background-color: rgba(0, 0, 0, 0.7);
      padding: 6px 16px;
      border-radius: 20px;
      cursor: pointer;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .record-date {
      text-align: center;
    }
  }

  .type-of-answer {
    display: flex;
    gap: var(--primary-gap);
    //margin-top: var(--primary-gap);
  }

  .rush-to-bottom-btn {
    position: absolute;
    bottom: 145px;
    right: 20px;
    cursor: pointer;
    z-index: 99999;

    img {
      width: 42px;
      height: auto;
    }
  }
}
