.post-dropdown-options {
  background-color: #353535;
  border-radius: var(--prim-radius);
  padding: 12px;
  width: 154px;
  margin-top: var(--secondary-gap);
  cursor: initial;
  position: absolute;
  right: -8px;

  .post-dropdown-action {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 8px;
    position: relative;
    border-radius: 30px;
    cursor: pointer;

    img {
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      width: 14px;
      height: auto;
      margin-top: 0.5px;
    }

    &:hover {
      background-color: var(--secondary-background);
    }
  }
}
