.comments-box-container {
  .sec-divider-line {
    width: 100%;
    height: 1px;
    background-color: var(--primary-background);
    margin: 20px 0;
  }

  .parent-comment {
    .child-comment {
      margin-left: 32px;
    }
  }
}
