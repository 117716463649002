.linear-progress-with-label {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 9999;

  .linear-progress-inner-container {
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    width: 30%;
    z-index: 9999;
    margin-left: 100px;
  }
}
