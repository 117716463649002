.history-table-container {
  margin-top: 6px;
  table {
    width: 100%;
    border-spacing: 0 12px;
    border-collapse: separate;
  }

  th,
  td {
    text-align: left;
  }

  th {
    color: var(--secondary-gray);
    font-weight: 400;
    padding: 0 20px;
  }

  td {
    padding: 20px;
    background-color: var(--primary-background);
  }

  td:first-child {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    width: 15%;
  }

  td:nth-child(2) {
    width: 15%;
  }

  td:nth-child(3) {
    width: 15%;
  }

  td:nth-child(4) {
    width: 15%;
  }

  td:nth-child(5) {
    width: 15%;
  }

  td:last-child {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }
}
