.editinfo-main-container {
  .user-edit-main {
    margin-bottom: 32px;
    .edit-top {
      display: flex;
      gap: var(--primary-gap);

      // Photo Edit Area - Start
      .pic-edit-sec {
        position: relative;
        width: 160px;
        height: 160px;
        border-radius: 50%;
        display: block;

        &::after {
          display: none;
          content: "個人照片";
          justify-content: center;
          align-items: center;
          width: 160px;
          height: 160px;
          border-radius: 50%;
          font-size: 16px;
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 1;
          position: absolute;
          top: 0;
          cursor: pointer;
        }

        &:hover {
          &::after {
            display: flex;
          }
        }

        .photo {
          width: 160px;
          height: 160px;
          border-radius: 50%;
          object-fit: cover;
        }

        .camera-icon {
          position: absolute;
          right: 0;
          bottom: 0;
          cursor: pointer;
          z-index: 2;
        }
      }
      // Photo Edit Area - End

      // Input Area - Start
      .info-edit-sec {
        display: flex;
        flex-direction: column;
        flex: 1;

        .info-top {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: var(--primary-gap);

          .associated {
            display: flex;
            align-items: center;
            width: 100%;
            height: 48px;
            background-color: var(--primary-background);
            border-radius: 24px;
            padding: 0 20px;
            position: relative;

            .media-icon {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 26px;
              height: 26px;
              border-radius: 50%;
              background-color: var(--primary-background);
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 20px;

              img {
                width: auto;
                height: 16px;
              }
            }
          }

          .name-error {
            padding: 8px 20px 10px 20px;
          }
        }

        .descr-area {
          margin-top: var(--primary-gap);
          flex: 1;
        }

        .name-error-gap {
          margin: 0;
        }
      }
      // Input Area - End
    }

    .descr-error {
      padding: 8px 20px 10px 200px;
    }

    // Background Edit
    .edit-bottom {
      margin-top: var(--primary-gap);

      .bg-edit-sec {
        width: 100%;
        height: 216px;
        border-radius: var(--prim-radius);
        background-color: var(--primary-background);
        position: relative;
        display: block;

        &::after {
          display: none;
          content: "背景照片";
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 216px;
          border-radius: var(--prim-radius);
          font-size: 16px;
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 1;
          position: absolute;
          top: 0;
          cursor: pointer;
        }

        &:hover {
          &::after {
            display: flex;
          }
        }

        img {
          width: 100%;
          height: 216px;
          border-radius: var(--prim-radius);
          object-fit: cover;
        }
      }
    }

    .descr-error-gap {
      margin: 0;
    }

    .lower-upload-area {
      display: flex;
      gap: var(--primary-gap);
      margin-top: var(--primary-gap);

      .app-bg-edit-sec {
        //width: 100%;
        height: 216px;
        aspect-ratio: 1.77/1;
        border-radius: var(--prim-radius);
        background-color: var(--primary-background);
        position: relative;
        display: block;

        &::after {
          display: none;
          content: "App背景照片(比例：16/9)";
          justify-content: center;
          align-items: center;
          //width: 100%;
          height: 216px;
          aspect-ratio: 1.77/1;
          border-radius: var(--prim-radius);
          font-size: 16px;
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 1;
          position: absolute;
          top: 0;
          cursor: pointer;
        }

        &:hover {
          &::after {
            display: flex;
          }
        }

        img {
          //width: 100%;
          height: 216px;
          aspect-ratio: 1.77/1;
          border-radius: var(--prim-radius);
          object-fit: cover;
        }
      }

      .business-card-edit-sec {
        //width: 100%;
        height: 216px;
        aspect-ratio: 1/0.618;
        border-radius: var(--prim-radius);
        background-color: var(--primary-background);
        position: relative;
        display: block;

        &::after {
          display: none;
          content: "名片照片(比例：1/0.618)";
          justify-content: center;
          align-items: center;
          //width: 100%;
          height: 216px;
          aspect-ratio: 1/0.618;
          border-radius: var(--prim-radius);
          font-size: 16px;
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 1;
          position: absolute;
          top: 0;
          cursor: pointer;
        }

        &:hover {
          &::after {
            display: flex;
          }
        }

        img {
          //width: 100%;
          height: 216px;
          aspect-ratio: 1/0.618;
          border-radius: var(--prim-radius);
          object-fit: cover;
        }
      }

      .btn-group {
        margin-left: auto;
        display: flex;
        gap: var(--primary-gap);
      }

      .delete-btn {
        width: 220px;
      }

      .save-btn {
        //display: block;
        width: 220px;
      }
    }
  }
}
